import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import ContentSectionMarkdown from "../../components/homepage/ContentSection-md"

const BookReviews = props => {
  // const { BookReviews } = useSelector(state => ({
  //   ...state.mainReducer.data,
  // }))

  return (
    <Layout>
      <hr style={{ background: "white" }} />
      <ContentSectionMarkdown
        title="Top 10 Lists"
        data={props.data.BookReviews_md}
        lg={4}
        xs={12}
        sm={6}
        height="200px"
        style={{ image: "cover", height: "200px" }}
      />
    </Layout>
  )
}
export default BookReviews

export const pageQuery = graphql`
  {
    BookReviews_md: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "Book Reviews" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subheading
            tags
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
